import React, { useState } from "react";
import AppBar from "../components/AppBar";
import VerificationProgressBar from "../components/VerificationProgressBar";
import VerificationForm from "../components/VerificationForm.jsx";
import VerificationSelfie from "../components/VerificationSelfie";
import VerificationSignature from "../components/VerificationSignature";
import VerificationAgreement from "../components/VerificationAgreement";
import VerificationComplete from "../components/VerificationComplete";
import ImportantNotice from "../components/ImportantNotice";
import "./Verification.css";
import {
  reSubmitVerificationDocument,
  submitVerificationDocument,
} from "../api/customer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  getSpecificUserDocument,
  convertSignature,
  getKycDocumentTypes,
  extractInformationOCR,
  matchFace,
  matchSignature,
  getDocumentUrl,
  getDocument,
  updateDocumentContent,
  updateDocumentStatus,
  updateDocumentNote,
} from "../api/document";
import { toast } from "react-toastify";
import moment from "moment";
import { set } from "lodash";
import OverlayLoader from "../components/OverlayLoader";
import FadeLoader from "react-spinners/FadeLoader";
import PersonalDetails from "../components/PersonalDetails";

// ----------------------VERIFICATION PROCESS PAGE------------------

const Verification = ({ showNav, noNav }) => {
  showNav(true);
  noNav(false);

  const navigate = useNavigate();
  const maxSize =
    (process.env.REACT_APP_FILE_UPLOAD_SIZE_LIMIT
      ? process.env.REACT_APP_FILE_UPLOAD_SIZE_LIMIT
      : 8) *
    1024 *
    1024; // 10MB
  const maxSizeInMB = maxSize / 1024 / 1024;
  const [uploading, setUploading] = useState(false);
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [resubmitting, setReSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentToResubmit, setDocumentToResubmit] = useState();
  const [typeOfDocumentToResubmit, setTypeOfDocumentToResubmit] = useState();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(
    "Select a document type"
  );
  const [documentToUpload, setDocumentToUpload] = useState();
  const [screenshot, setScreenshot] = useState();

  const [imageSignatureCloseup, setImageSignatureCloseup] = useState();

  const [imageIdSignature, setImageIdSignature] = useState();

  const [dobValue, setDOBValue] = useState(null);
  const [line1, setline1] = useState("");
  const [line2, setline2] = useState("");
  const [city_town_district, setcity] = useState("");
  const [state_parish_pronvince, setstate] = useState("");
  const [idIssueDateValue, setIdIssueDateValue] = useState(null);
  const [idExpirationDateValue, setIdExpirationDateValue] = useState(null);
  const [fullnameValue, setFullnameValue] = useState(null);
  const [idNumber, setIdNumber] = useState(null);

  const [formHtml, setFormHtml] = useState();
  const [formPdfBase64, setFormBase64] = useState();

  const [registrationHtml, setRegistrationHtml] = useState();

  const [index, setIndex] = useState(1);

  const [frontBase64, setFrontBase64] = useState();
  const [backBase64, setBackBase64] = useState();
  const [sigImage, setSigImage] = useState();

  let [color, setColor] = useState("#008ffd");
  const [selfieMatch, setSelfieMatch] = useState(false);

  const [idData, setIdData] = useState(null);
  const [personalData, setPersonalData] = useState(null);

  const [show, setShow] = useState(true);

  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);

  const [ocrData, setOcrData] = useState();
  const [sigResult, setSigResult] = useState();

  // FAIL COUNT FOR SIGNATURE
  let [failcount, setFailCount] = useState(0);
  // FAIL COUNT FOR FACEMATCH
  let [faceFailcount, setFaceFailCount] = useState(0);

  const [processing, setProcessing] = useState(false);

  const loadDocumentToResubmit = async () => {
    setLoading(true);
    try {
      if (id != null) {
        const doctypes = await getKycDocumentTypes();
        if (doctypes.includes(id)) {
          setDocumentToResubmit({});
          setTypeOfDocumentToResubmit(id);
        } else {
          const doc = await getSpecificUserDocument(id);
          setDocumentToResubmit(doc);
          setTypeOfDocumentToResubmit(doc?.documentType.Type_Name);
        }

        setIndex(0);
        // debugger;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const buildAndSendSubmissionObject = async () => {
    // debugger;

    const dobFormatted = moment(dobValue).format("MMMM Do YYYY");
    const dobFormatted_SQL = moment(dobValue).format("YYYY-MM-DD");
    const idExpirationDateFormatted = moment(idExpirationDateValue).format(
      "YYYY-MM-DD"
    );
    const idIssueDateFormatted = moment(idIssueDateValue).format("YYYY-MM-DD");

    const obj = {
      documentToUpload,
      imageIdSignature,
      screenshot,
      formHtml,
      formPdfBase64,
      registrationHtml,
      dobValue: dobFormatted,
      dob: dobFormatted_SQL,
      address: personalData?.line1||line1,
      line2: personalData?.line2||line2,
      city_town_district: personalData?.city||city_town_district,
      postalCode: personalData?.postalCode||"",
      state_parish_pronvince: personalData?.state||state_parish_pronvince,
      selectedCountry: personalData?.country||selectedCountry,
      idExpirationDateValue: idExpirationDateFormatted,
      idIssueDateValue: idIssueDateFormatted,
      identification_type: selectedDocument,
      fullnameValue,
      idNumber,
    };

    try {
      await submitVerificationDocument(obj);
    } catch (err) {
      setUploading(false);
      console.error(err);
      toast(
        "An error occurred while submitting your documents. Please contact support for assistance!",
        {
          type: "error",
        }
      );
    } finally {
      setUploading(false);
      setTimeout(() => {
        window.location.replace("/settings-kyc-verification");
      }, 1000);
    }
  };

  const resubmitDocument = async () => {
    try {
      if (index < 5 && typeOfDocumentToResubmit == null) {
        setIndex((prevIndex) => prevIndex + 1);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
      let obj;
      if (typeOfDocumentToResubmit != null) {
        let result;
        switch (typeOfDocumentToResubmit) {
          case "Form":
            obj = {
              formHtml,
              formPdfBase64,
              typeOfDocumentToResubmit,
              registrationHtml,
            };
            result = await reSubmitVerificationDocument(obj, id);
            break;
          case "Passport":
          case "National ID":
          case "Drivers License":
            if (documentToUpload?.type === typeOfDocumentToResubmit) {
              obj = {
                documentToUpload,
                typeOfDocumentToResubmit,
              };
            } else {
              obj = {
                documentToUpload,
                typeOfDocumentToResubmit: documentToUpload?.type,
              };
            }
            // debugger;
            result = await reSubmitVerificationDocument(obj, id);
            break;
          /* case "Signature Close-up":
            obj = {
              imageSignatureCloseup,
              typeOfDocumentToResubmit,
            };
            // debugger;
            result = await reSubmitVerificationDocument(obj, id);
            break; */
          case "Signed Signature":
            obj = {
              imageIdSignature,
              typeOfDocumentToResubmit,
            };
            // debugger;
            result = await reSubmitVerificationDocument(obj, id);
            break;
          case "Selfie":
            obj = {
              screenshot,
              typeOfDocumentToResubmit,
            };
            // debugger;
            result = await reSubmitVerificationDocument(obj, id);
            break;
        }
        toast("Successfully resubmitted document", {
          type: "success",
        });
        setTimeout(() => {
          navigate("/settings-kyc-verification");
        }, 2000);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setReSubmitting(false);
    }
  };
  const sendSubmission = async () => {
    try {
      setUploading(true);
      const res = await buildAndSendSubmissionObject();
      setSubmitting(false);
    } catch (err) {
      setUploading(false);
      window.rollbar.error(err);
    }
  };

  const continueBtn = async () => {
    if (documentToResubmit != null) {
      setReSubmitting(true);
    } else {
      debugger;
      if (index < 5) {
        setIndex(index + 1);
      } else {
        setSubmitting(true);
      }
    }
    // debugger;
  };

  const matchSelfie = async () => {
    if (screenshot != null && imageSignatureCloseup != null && index >= 3) {
      const matchResult = await matchFace(imageSignatureCloseup, screenshot);
      const matched = matchResult?.data === "matched";
      if (!matched) {
        setSelfieMatch(false);
        setIndex(3);
        toast.error("Selfie and Signature Closeup does not match!");
      } else {
        setSelfieMatch(true);
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const extractID = async (base64) => {
    const res = await extractInformationOCR(base64);
    console.log("test1", res.data);
    setIdData(res.data);
  };

  const confirmDocumentSection = async (front, back) => {
    const document = {
      type: selectedDocument,
      files: [],
    };
    if (front != null) {
      const base64 = await toBase64(front);
      document.files.push(base64);
      // await extractID(base64);
    }
    if (back != null) {
      const base64 = await toBase64(back);
      document.files.push(base64);
    }

    // // debugger;
    setDocumentToUpload(document);
  };

  const submit = (e) => {
    confirmDocumentSection(frontFile, backFile);
    setTimeout(() => {
      continueBtn(e);
    }, 1000);
  };

  const handleOCRRequest = async (e) => {
    // const timeoutDuration = 2000; // 2 seconds

    try {
      setProcessing(true);
      const response = await extractInformationOCR(frontBase64);

      if (response) {
        setProcessing(false);
        console.log("Response:", response);
        setOcrData(response.result);
        console.log(ocrData);

        // const result = await response.json();

        // console.log("Result:", response);
        toast.success("Success");
        // You can use the score to determine if the signature is a match
        // For example, if the score is above a certain threshold, it's a match
        // You can implement your logic here

        // Continue with the next steps based on the result
        // continueBtn(); // Assuming continueBtn is a function to proceed
        submit(e);
      } else {
        setProcessing(false);
        toast.error("error");
      }
    } catch (error) {
      setProcessing(false);
      console.error("Error:", error);
      toast.error(
        "An error occurred while comparing images. Please try again later."
      );
    }
  };

  const handleVerification = async (e) => {
    setProcessing(true);
    // Make sure both the ID file and signature are selected
    if (!frontBase64 && !sigImage) {
      setProcessing(false);
      toast.error("Please upload both the ID file and the signature.");
      return;
    }

    try {
      setProcessing(true);
      const response = await matchSignature(frontBase64, sigImage);

      if (response) {
        setProcessing(false);
        console.log("Result:", response);
        console.log(response.matched);
        // 'result' should contain the score indicating if the signature is a match
        if (response.matched === true) {
          toast.success("Successful Signature Comparison");
          setSigResult(true);
          continueBtn(e);
        } else {
          setFailCount(failcount + 1);
          console.log(failcount);
          toast.error("Automatic Signature Verification Failed");
        }

        // You can use the score to determine if the signature is a match
        // For example, if the score is above a certain threshold, it's a match
        // You can implement your logic here

        // Continue with the next steps based on the result
        // continueBtn(); // Assuming continueBtn is a function to proceed
      } else {
        setProcessing(false);
        toast.error("Failed to compare images. Please try again later.");
      }
    } catch (error) {
      setProcessing(false);
      console.error("Error:", error);
      toast.error(
        "An error occurred while comparing images. Please try again later."
      );
      setSigResult(false);
    }
  };

  const faceVerification = async () => {
    if (!screenshot) {
      toast.error("Please upload an image");
      return;
    }
    try {
      setProcessing(true);
      const response = await matchFace(frontBase64, screenshot);

      if (response) {
        setProcessing(false);
        console.log("Result:", response);
        console.log(response.matched);
        // 'result' should contain the score indicating if the signature is a match
        if (response.matched === true) {
          toast.success("Successful Image Comparison");

          continueBtn();
        } else {
          setFaceFailCount(faceFailcount + 1);
          console.log(faceFailcount);
          toast.error("Automatic Face Comparison Verification Failed");
        }

        // You can use the score to determine if the signature is a match
        // For example, if the score is above a certain threshold, it's a match
        // You can implement your logic here

        // Continue with the next steps based on the result
        // continueBtn(); // Assuming continueBtn is a function to proceed
      } else {
        setProcessing(false);
        toast.error("Failed to compare images. Please try again later.");
      }
    } catch (error) {
      setProcessing(false);
      console.error("Error:", error);
      toast.error(
        "An error occurred while comparing images. Please try again later."
      );
    }
  };

  useEffect(() => {
    loadDocumentToResubmit();
    // console.log("doc", documentToResubmit);
  }, []);
  useEffect(() => {
    matchSelfie();
  }, [screenshot]);

  useEffect(() => {
    if (
      (screenshot != null ||
        imageIdSignature != null ||
        documentToUpload != null ||
        formHtml != null) &&
      resubmitting
    ) {
      debugger;
      resubmitDocument();
      setReSubmitting(false);
    }
  }, [documentToUpload, screenshot, imageIdSignature, formHtml, resubmitting]);

  useEffect(() => {
    // debugger;
    if (
      screenshot != null &&
      documentToUpload != null &&
      imageIdSignature != null &&
      formPdfBase64 != null &&
      registrationHtml != null &&
      submitting
    ) {
      sendSubmission().then();
    }
  }, [formPdfBase64, submitting, registrationHtml]);

  return (
    !loading && (
      <>
        {uploading ? (
          <div className="bg-white fixed flex justify-center items-center w-[100vw] h-[100vh] z-[9999]">
            <FadeLoader
              color={color}
              loading={uploading}
              size={80}
              className="mr-0 md:mr-32"
            />
          </div>
        ) : null}

        <div className="dashboard-page">
          <AppBar title="KYC Verification"></AppBar>

          <div className="dashboard-container">
            {processing ? (
              <div className="absolute flex items-center justify-center w-full h-screen z-[9999] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                <FadeLoader
                  color="#008ffd"
                  loading={processing}
                  size={80}
                  className="mr-0"
                />
              </div>
            ) : null}
            <div className="verification-form-container">
              {show && !id ? (
                <ImportantNotice setShow={setShow}></ImportantNotice>
              ) : null}
              {typeOfDocumentToResubmit == null && (
                <VerificationProgressBar
                  step={index}
                  setStep={setIndex}
                ></VerificationProgressBar>
              )}
              {(index === 1 ||
                typeOfDocumentToResubmit == "National ID" ||
                typeOfDocumentToResubmit == "Drivers License" ||
                typeOfDocumentToResubmit == "Passport") && (
                <VerificationForm
                  continueBtn={continueBtn}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  selectedDocument={selectedDocument}
                  setSelectedDocument={setSelectedDocument}
                  confirmDocumentSection={confirmDocumentSection}
                  maxSize={maxSize}
                  maxSizeInMB={maxSizeInMB}
                  frontBase64={frontBase64}
                  backBase64={backBase64}
                  setFrontBase64={setFrontBase64}
                  setBackBase64={setBackBase64}
                  handleOCRRequest={handleOCRRequest}
                  frontFile={frontFile}
                  backFile={backFile}
                  setFrontFile={setFrontFile}
                  setBackFile={setBackFile}
                  processing={processing}
                  documentToResubmit={documentToResubmit}
                  resubmitDocument={resubmitDocument}
                  submit={submit}
                ></VerificationForm>
              )}
              {index === 2 && (
                <div className="verification-form">
                  <div className="verification-heading">
                    <h1>ID details</h1>
                    <p>
                      Kindly confirm some further details about your <br />
                      Please ensure all required fields are filled out and match
                      your government-issued ID.
                    </p>
                  </div>

                  <PersonalDetails
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    continueBtn={continueBtn}
                    data={idData}
                    setPersonalData={setPersonalData}
                    ocrData={ocrData}
                    setOcrData={setOcrData}
                    selectedDocument={selectedDocument}
                  ></PersonalDetails>
                </div>
              )}
              {(index === 3 || typeOfDocumentToResubmit == "Selfie") && (
                <VerificationSelfie
                  continueBtn={continueBtn}
                  saveScreenshot={setScreenshot}
                  heading={"Upload a Selfie holding id beside face"}
                  maxSize={maxSize}
                  maxSizeInMB={maxSizeInMB}
                  faceFailcount={faceFailcount}
                  faceVerification={faceVerification}
                  screenshot={screenshot}
                  processing={processing}
                  documentToResubmit={documentToResubmit}
                  resubmitDocument={resubmitDocument}
                ></VerificationSelfie>
              )}
              {(index === 4 ||
                typeOfDocumentToResubmit == "Signed Signature") && (
                <VerificationSignature
                  sigImage={sigImage}
                  setSigImage={setSigImage}
                  continueBtn={continueBtn}
                  saveScreenshot={setImageSignatureCloseup}
                  maxSize={maxSize}
                  maxSizeInMB={maxSizeInMB}
                  documentToUpload={documentToUpload}
                  imageIdSignature={imageIdSignature}
                  setImageIdSignature={setImageIdSignature}
                  frontBase64={frontBase64}
                  setFrontBase64={setFrontBase64}
                  handleVerification={handleVerification}
                  sigResult={sigResult}
                  failcount={failcount}
                  processing={processing}
                  documentToResubmit={documentToResubmit}
                  resubmitDocument={resubmitDocument}
                ></VerificationSignature>
              )}
              {(index === 5 || typeOfDocumentToResubmit == "Form") && (
                <VerificationAgreement
                  sigImage={sigImage}
                  maxSize={maxSize}
                  maxSizeInMB={maxSizeInMB}
                  screenshot={screenshot}
                  documentToUpload={documentToUpload}
                  resubmit={documentToResubmit != null}
                  continueBtn={continueBtn}
                  selectedDocument={selectedDocument}
                  setFormHtml={setFormHtml}
                  formBase64={formPdfBase64}
                  setFormBase64={setFormBase64}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  buildSubmissionObject={buildAndSendSubmissionObject}
                  setRegistrationHtml={setRegistrationHtml}
                  imageIdSignature={imageIdSignature}
                  setImageIdSignature={setImageIdSignature}
                  setDOBValue={setDOBValue}
                  setLine1={setline1}
                  setLine2={setline2}
                  setCity={setcity}
                  setState={setstate}
                  line1={line1}
                  setIdIssueDateValue={setIdIssueDateValue}
                  setIdExpirationDateValue={setIdExpirationDateValue}
                  setFullnameValue={setFullnameValue}
                  setIdNumber={setIdNumber}
                  convertSignature={convertSignature}
                  personalData={personalData}
                ></VerificationAgreement>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Verification;
