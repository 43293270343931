export const withdrawTableData = [
  {
    id: 1,
    withdrawmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 2,
    withdrawmethod: "Credit card(***65)",
    status: "Decline",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "decline",
  },
  {
    id: 3,
    withdrawmethod: "Credit card(***65)",
    status: "Proceeding",
    date: "23/05/22",
    amount: "$100000.00",
    class: "proceeding",
  },
  {
    id: 4,
    withdrawmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
];
