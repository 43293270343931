import React, { useState, useRef, useEffect } from "react";
import upload from "../images/icons/upload.svg";
import SignatureModal from "./SignatureModal";
import VerificationReview from "./VerificationReview";
import toast, { Toaster } from "react-hot-toast";
import { dailCodes } from "../components/DailCodeList";
import close from "../images/icons/closedocbtn.svg";
// https://reactdatepicker.com/
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// react-calendar
// https://www.npmjs.com/package/react-calendar
import Calendar from "react-calendar";
import moment from "moment";

import pen from "../images/icons/pen.svg";
// css is in
import "../pages/Verification.css";
import "react-calendar/dist/Calendar.css";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";

import $ from "jquery";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const VerificationAgreement = ({
  setRegistrationHtml,
  screenshot,
  resubmit,
  continueBtn,
  selectedDocument,
  selectedCountry,
  setSelectedCountry,
  setFormHtml,
  setFormBase64,
  formBase64,
  formHtml,
  documentToUpload,

  imageIdSignature,
  setImageIdSignature,
  setDOBValue,
  setIdIssueDateValue,
  setIdExpirationDateValue,
  setFullnameValue,
  setIdNumber,
  convertSignature,
  maxSize,
  maxSizeInMB,
  setLine1,
  setLine2,
  setCity,
  setState,
  personalData,
  sigImage,
}) => {
  const [formEmbedHtml, setFormEmbedHtml] = useState();
  const [documentSetPreviously] = useState(
    selectedDocument != "" && selectedDocument != "Select a document type"
  );
  const [document, setDocument] = useState(selectedDocument);
  const customer = useSelector(selectCurrentCustomer);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //expdate picker
  const [dateOpen, setDateOpen] = useState(false);
  const [datevalue, setdatevalue] = useState(null);
  const [issuedatevalue, setissuedatevalue] = useState(null);

  // date of birth
  const [dobOpen, setdobOpen] = useState(false);
  const [dobvalue, setdobvalue] = useState(null);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const current = new Date();
  const cdate = `${current.getDate()}-${current.getFullYear()}`;

  const d = new Date();
  const monthName = months[d.getMonth()];
  const fullDate = `${monthName}-${cdate}`;

  const [documentType, setDocumentType] = useState(selectedDocument);

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [fullname, setfullname] = useState();

  const [address, setaddress] = useState("");
  const [number, setnumber] = useState("");
  const [email, setemail] = useState("");

  const [idnumber, setidnumber] = useState("");
  const [line1, setline1] = useState("");
  const [line2, setline2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zipcode, setzipcode] = useState("");

  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);

  useEffect(() => {
    setfirstname(personalData?.first_name || "");
    setlastname(personalData?.last_name || "");
    setcity(personalData?.city || "");
    setstate(personalData?.state || "");
    setline1(personalData?.line1 || "");
    setline2(personalData?.line2 || "");
    setzipcode(personalData?.postalCode || "");
    setidnumber(personalData?.idNumber || "");
    setissuedatevalue(personalData?.issueDate);
    setdatevalue(personalData?.expiryDate);
    setdobvalue(personalData?.dob);
    if (personalData?.gender === "Male") {
      setMale(true);
    }
    if (personalData?.gender === "Female") {
      setFemale(true);
    }
  }, [personalData]);

  const [openModal, setOpenModal] = useState(false);
  const [openReview, setOpenReview] = useState(false);

  const acceptedFileTypes = "image/png, image/jpeg";
  const sigImageRef = useRef();

  // COUNTRY DROWPDOWN
  const [isActive, setIsActive] = useState(false);
  const options = dailCodes;
  const [numRemaining, setNumRemaining] = useState(6);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (customer != null) {
      setfirstname(customer.Firstname);
      setlastname(customer.Lastname);
      setnumber(customer.Telephone_Cell);
      setemail(customer.Email);
    }
  }, [customer]);
  let signaturePad = useRef({});
  let signature = "";

  function clear() {
    signaturePad.current.clear();
  }

  function isEmpty() {
    signaturePad.current.isEmpty();
  }

  const getFormHtml = () => {
    const elementArr = window.document.querySelectorAll(
      ".verification-agreement-box"
    );
    // debugger;
    let html = "";
    for (let counter = 0; counter < elementArr.length; counter++) {
      const element = elementArr[counter];
      html += element.innerHTML;
    }
    let dom = $(html);
    dom.find('input[type="text"]').each(function () {
      const elem = $(this);
      console.log("@@@", elem);
      const value = $(this).attr("value");
      elem.replaceWith(
        `<span class="swapped" style="padding-left: 5px; padding-right: 5px;">${
          value != null ? value : ""
        }</span>`
      );
    });

    dom.find('input[type="date"]').each(function () {
      const elem = $(this);
      console.log("@@@", elem);
      const value = $(this).attr("value");
      elem.replaceWith(
        `<span class="swapped" style="padding-left: 5px; padding-right: 5px;">${
          value != null ? value : ""
        }</span>`
      );
    });

    dom.find('input[type="checkbox"]').each(function () {
      const elem = $(this);
      const label = elem.siblings(".checkbox-label").first();
      if (!elem.is(":checked")) {
        if (label.text() == "Male" && male) {
          elem.remove();
        } else if (label.text() == "Female" && female) {
          elem.remove();
        } else {
          elem.remove();
          label.remove();
        }
      } else {
        elem.remove();
        debugger;
      }
    });

    dom.find(".remove,.react-calendar").each(function () {
      const elem = $(this);
      elem.replaceWith("<br/>");
    });
    html = "";

    dom.each(function () {
      html += $(this).html();
    });
    html = html.replace("undefiend", "");
    console.log("@@@", html);
    setFormHtml(html);
    return html;
  };
  const fieldsToCheck = {
    dobvalue,
    firstname,
    lastname,
    line1,
    number,
    email,
    idnumber,
    sigImage,
    state,
    selectedCountry,
    zipcode,
    document,
    datevalue,
    issuedatevalue,
  };
  const fieldLabels = {
    firstname: "First Name",
    lastname: "Last Name",
    line1: "Address Line 1",
    number: "Phone Number",
    email: "Email",
    idnumber: "ID Number",
    sigImage: "Signature",
    state: "State",
    city: "City",
    selectedCountry: "Country",
    zipcode: "Zip Code",
    document: "Document Type",
    datevalue: "ID Expiry Date",
    issuedatevalue: "ID Issue Date",
    dobvalue: "Date of Birth",
  };
  const [missing, setMissing] = useState([]);
  useEffect(() => {
    setDocumentType(selectedDocument);
  }, []);

  useEffect(() => {
    let numRemaining = Object.keys(fieldsToCheck).length;
    const missing = [];
    for (const [key, field] of Object.entries(fieldsToCheck)) {
      if (field != null && field != "" && field != "Select a document type") {
        numRemaining--;
      } else {
        missing.push(fieldLabels[key]);
      }
    }
    if (!(male || female)) {
      numRemaining++;
      missing.push("Gender");
    }
    // debugger;
    setMissing([...missing]);
    setNumRemaining(numRemaining);
    setIsValid(numRemaining == 0);
  }, [...Object.values(fieldsToCheck), male, female]);

  return (
    customer != null && (
      <div className="verification-form">
        {/* {openModal && (
          <SignatureModal
            maxSize={maxSize}
            maxSizeInMB={maxSizeInMB}
            closeModal={setOpenModal}
            firstname={firstname}
            lastname={lastname}
            signature={signature}
            signaturePad={signaturePad}
            setSigImage={setSigImage}
            sigImage={sigImage}
            save={save}
            clear={clear}
            isEmpty={isEmpty}
            upload={upload}
            setSigImageBase64={setSigImageBase64}
            sigImageBase64={sigImageBase64}
            acceptedFileTypes={acceptedFileTypes}
            sigImageRef={sigImageRef}
          ></SignatureModal>
        )} */}
        {openReview && (
          <VerificationReview
            formEmbedHtml={formEmbedHtml}
            screenshot={screenshot}
            documentToUpload={documentToUpload}
            continueBtn={continueBtn}
            formBase64={formBase64}
            datevalue={datevalue}
            issuedatevalue={issuedatevalue}
            setdatevalue={setdatevalue}
            dateOpen={dateOpen}
            setDateOpen={setDateOpen}
            closeModal={setOpenReview}
            firstname={firstname}
            setfirstname={setfirstname}
            lastname={lastname}
            setlastname={setlastname}
            fullname={fullname}
            setfullname={setfullname}
            address={address}
            number={number}
            setnumber={setnumber}
            dobvalue={dobvalue}
            setdobvalue={setdobvalue}
            dobOpen={dobOpen}
            setdobOpen={setdobOpen}
            email={email}
            setemail={setemail}
            fullDate={fullDate}
            selectedDocument={selectedDocument}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            sigImage={sigImage}
            male={male}
            setMale={setMale}
            female={female}
            setFemale={setFemale}
            idnumber={idnumber}
            setidnumber={setidnumber}
            line1={line1}
            setline1={setline1}
            line2={line2}
            setline2={setline2}
            state={state}
            setstate={setstate}
            zipcode={zipcode}
            setzipcode={setzipcode}
            city={city}
            setcity={setcity}
            setRegistrationHtml={setRegistrationHtml}
            imageIdSignature={imageIdSignature}
          ></VerificationReview>
        )}
        <div className="verification-heading">
          <h1>Apex e-commerce services customer agreement</h1>
          <p>
            To complete verification, please read the following agreement and
            continue to accept.
          </p>
        </div>
        <div className="verification-agreement-box">
          <div className="pdf">
            {/* PAGE 1 */}

            <div className="pdfpage">
              <div className="pdf-top">
                <div className="agreement-row">
                  <p>
                    <b>Client’s Personal Information</b>
                  </p>
                </div>
                <div className="agreement-name-row agreement-row ">
                  <div className="agreement-row">
                    <p>First Name:</p>
                    <span>
                      <input
                        type="text"
                        value={firstname}
                        placeholder="Enter your first name here"
                        readOnly
                        style={{ width: `${firstname.length}ch` }}
                      />
                    </span>
                  </div>
                  <div className="agreement-row">
                    <p>Last Name:</p>
                    <span>
                      <input
                        type="text"
                        value={lastname}
                        placeholder="Enter your last name here"
                        readOnly
                        style={{ width: `${lastname.length}ch` }}
                      />
                    </span>
                  </div>
                </div>

                <div className="agreement-name-row agreement-row">
                  <p>Gender:</p>
                  <div className="agreement-row">
                    <span>
                      <input
                        type="checkbox"
                        checked={male}
                        readOnly
                        defaultChecked={male ? true : false}
                      />

                      <p class="checkbox-label">Male</p>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={female}
                        readOnly
                        defaultChecked={female ? true : false}
                      />
                      <p class="checkbox-label">Female</p>
                    </span>
                  </div>
                </div>
                <div className="agreement-row">
                  <div className="agreement-row">
                    <p>Date of Birth:</p>
                    <span className="relative">
                      <div className="flex">
                        <input type="date" required value={dobvalue} readOnly />
                        {/* <input
                          type="text"
                          value={
                            dobvalue
                              ? moment(dobvalue).format("MMMM Do YYYY")
                              : null
                          }
                          placeholder="Month-Day-Year"
                          // onChange={(e) => setdob(e.target.value)}
                          // style={{ width: `${dob.length}ch` }}
                          onClick={() => setdobOpen(!dobOpen)}
                        /> */}
                      </div>
                    </span>
                  </div>
                </div>

                <div className="agreement-row">
                  <div className="agreement-row">
                    <p>Phone Number: </p>
                    <span>
                      <input
                        type="text"
                        value={number}
                        placeholder="Enter your phone number"
                        readOnly
                        style={{ width: `${address.length}ch` }}
                      />
                    </span>
                  </div>
                </div>

                <div className="agreement-row">
                  <div className="agreement-row">
                    <p>Enter Email Address:</p>
                    <span>
                      <input
                        type="text"
                        value={email}
                        placeholder="Enter your email address"
                        readOnly
                        style={{ width: `${email.length}ch` }}
                      />
                    </span>
                  </div>
                </div>

                <div className="agreement-row">
                  <p>
                    <b>Address</b>
                  </p>
                </div>

                <div className="agreement-row">
                  <p>Line 1 :</p>
                  <span>
                    <input
                      type="text"
                      value={line1}
                      onChange={(e) => {
                        setline1(e.target.value);
                        setLine1(e.target.value);
                      }}
                      readOnly
                      style={{ width: `${line1.length}ch` }}
                    />
                  </span>
                </div>

                <div className="agreement-row">
                  <p>Line 2 :</p>
                  <span>
                    <input
                      type="text"
                      value={line2}
                      placeholder="{optional apt # etc}"
                      onChange={(e) => {
                        setline2(e.target.value);
                        setLine2(e.target.value);
                      }}
                      readOnly
                      style={{ width: `${line2.length}ch` }}
                    />
                  </span>
                </div>

                <div className="agreement-row">
                  <p>City :</p>
                  <span>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                        setCity(e.target.value);
                      }}
                      readOnly
                      style={{ width: `${city.length}ch` }}
                    />
                  </span>
                </div>
                <div className="agreement-row">
                  <p>State/parish/providence/region :</p>
                  <span>
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => {
                        setstate(e.target.value);
                        setState(e.target.value);
                      }}
                      readOnly
                      style={{ width: `${state.length}ch` }}
                    />
                  </span>
                </div>
                <div className="agreement-row">
                  <p>Postal/Zip Code :</p>
                  <span>
                    <input
                      type="text"
                      value={zipcode}
                      readOnly
                      style={{ width: `${zipcode.length}ch` }}
                    />
                  </span>
                </div>
                <div className="agreement-row">
                  <p>Country :</p>
                  <div className="country-btn">
                    <span className="country-select">
                      <input
                        type="text"
                        readOnly
                        placeholder="Select Country"
                        value={selectedCountry}
                      />
                    </span>
                    {isActive && (
                      <div className="country-content">
                        {options.map((option) => (
                          <div
                            key={option}
                            onClick={(e) => {
                              debugger;
                              setSelectedCountry(e.target.textContent);
                              setIsActive(false);
                            }}
                            className="country-item"
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="agreement-row">
                  <p>Date Signed:</p>
                  <span>
                    <input
                      type="text"
                      placeholder=""
                      value={fullDate}
                      readOnly
                    />
                  </span>
                </div>
                <span className="pdf-subject">
                  <p>To: Apex E-Commerce Services LLC</p>
                  <p>
                    <u>
                      Re: Payment Authorization For Apex E-Commerce Services
                      LLC’s Online Software Services.
                    </u>
                  </p>
                </span>
              </div>
              <div className="pdf-body">
                <p>
                  I
                  <span>
                    <input
                      type="text"
                      placeholder="Enter your full name here"
                      value={firstname + " " + lastname}
                      // onChange={(e) => setfullname(e.target.value)}
                    />
                  </span>
                  holder of Government ID type:
                  <input
                    type="checkbox"
                    checked={document === "Drivers License" ? true : false}
                    onChange={() => {
                      debugger;
                      if (!documentSetPreviously) {
                        setDocument("Drivers License");
                      }
                    }}
                  />
                  <span class="checkbox-label">Driver's License</span>
                  <input
                    type="checkbox"
                    checked={document === "Passport" ? true : false}
                    onChange={() => {
                      debugger;
                      if (!documentSetPreviously) {
                        setDocument("Passport");
                      }
                    }}
                  />
                  <span class="checkbox-label">Passport</span>
                  <input
                    type="checkbox"
                    checked={document === "National ID" ? true : false}
                    onChange={() => {
                      debugger;
                      if (!documentSetPreviously) {
                        setDocument("National ID");
                      }
                    }}
                  />
                  <span class="checkbox-label">National ID</span> and
                  <span> Government ID number</span>:
                  <span>
                    <input
                      type="text"
                      placeholder="Enter your id no:"
                      value={idnumber}
                      readOnly
                    />
                  </span>{" "}
                  <span> with Issue Date</span>:{" "}
                  <span>
                    <input
                      type="date"
                      required
                      value={issuedatevalue}
                      readOnly
                    />
                  </span>
                  <span> and Expiry Date</span>:{" "}
                  <span>
                    <input type="date" required value={datevalue} readOnly />
                  </span>
                  hereby confirm the authorization of the legality and validity
                  of payments made to Apex E-Commerce Services by card
                  payment/bank transfers.
                </p>
                <p>
                  I confirm that I am the authorized holder of the above stated
                  identification number, and the authorized card holder of the
                  card used to make the payments. Thereby, verifying this non-
                  refundable payment and future payments to Apex E-Commerce
                  Services LLC for access to their online software licenses
                  pursuant to Apex E-Commerce Services' terms of service.
                </p>
                <p>
                  I represent and warrant that any transaction in accordance
                  with this letter will serve as an act in full compliance with
                  all the applicable local, national and international laws and
                  regulations. Continually, I represent and warrant and certify
                  that I am by no means under investigation by any governmental
                  authority with regards to, have been charged with, or
                  convicted of; money laundering, drug trafficking, any
                  terrorist related activities, or any crimes which in any
                  jurisdiction would be predicate crimes or offenses that are in
                  contravention to anti-money laundering laws.
                </p>
                <p>
                  I affirm that I have not been assessed civil or criminal
                  penalties under any Anti money laundering law or have had any
                  seizure of funds or forfeiture of any action under any
                  anti-money laundering laws of any country/ jurisdiction.
                </p>
                <p>
                  Therefore, I represent and warrant that the funds for this
                  monetary payment to Apex E- Commerce Services does not and
                  will not derive from any illegal activity that violates any
                  Anti Money Laundering Laws and Regulations.
                </p>
                <p>
                  I recognize that by law, Apex E-Commerce Services may disclose
                  my name and identity in the event that such information is
                  requested in relation to this letter. Additionally, I will
                  notify Apex E- Commerce Services at once, when it is known to
                  me that said representation is fallacious or becomes
                  fallacious.
                </p>
                <div class="html2pdf__page-break"></div>
                <p>
                  Finally, subject to and upon the terms and conditions of this
                  agreement, I shall indemnify and hold harmless Apex E-Commerce
                  Services, its employees, affiliates and directors from any
                  claim or disputes that may arise as a result of any payments
                  made by me in relation to Apex E- Commerce Services software
                  license agreement/s.
                </p>
                <p>
                  I further confirm that this is a non refundable monetary
                  payment conducted pursuant to Apex E- Commerce Services' terms
                  of service.
                </p>
              </div>
              <div className="pdf-flex">
                <div>
                  <p>Client’s Full Name:</p>

                  <span>
                    <input
                      type="text"
                      placeholder=""
                      value={firstname + " " + lastname}
                      // onChange={(e) => setfullname(e.target.value)}
                    />
                  </span>
                </div>

                <div className="pdf-signature">
                  <p>
                    Client’s Signature:
                    {/* <button onClick={clear}>Clear</button>
                  <button onClick={save}>Save</button> */}
                  </p>

                  {sigImage ? (
                    <img
                      src={sigImage}
                      alt="my signiture"
                      style={{
                        display: "block",
                        width: "150px",
                        height: "50px",
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {/* <div>
                  <SignaturePad ref={signaturePad}></SignaturePad>
                </div> */}
                </div>
              </div>
            </div>
            {/* PAGE 2 */}
            <div className="pdfpage">
              <div className="pdf-body">
                <p>
                  We, <b>Apex E-Commerce Services LLC</b>, acknowledge the above
                  statements.
                </p>
                <p>
                  We acknowledge and understand that payments shall be made
                  through our corporate bank account in the name Apex E-Commerce
                  Services. We further confirm that payments made by the named
                  payer will reach the stated bank account. If this is to change
                  for any given reason, it is our duty to promptly inform the
                  payer of such changes.
                </p>
                <p>
                  Apex E-Commerce Services LLC represents and warrants that any
                  activity in relation to this payment letter will be in full
                  compliance with all applicable local, national and
                  international laws, rules and regulations. This includes
                  Anti-Money Laundering laws and regulation of any applicable
                  jurisdiction or to respond to requests for information
                  concerning the identity of the payer from any government
                  Authority, regulatory organization or financial institution in
                  connection with its anti-money laundering compliance and to
                  update such information as necessary.
                </p>
                <p>
                  Conversely, in compliance with anti-money laundering laws and
                  other regulatory bodies related to this issue, we agree to
                  provide at any time further documents verifying the identity
                  of the named payer and the source of funds used for payment
                  transfer towards our bank account.
                </p>
                <p>
                  <b>
                    Apex E-Commerce Services LLC holds a strict NO REFUND POLICY{" "}
                  </b>
                </p>
                <p>
                  You may cancel your Service by contacting Apex E-Commerce
                  Services support via email. If the client makes a request for
                  cancellation after payment, the client is not entitled to a
                  refund.
                </p>
                <p>
                  Additionally, If you made any payment error, you may contact
                  us as soon as reasonably possible and we will assist you in
                  resolving the issue. If a refund is requested without
                  contacting us it will be held invalid and will risk your
                  account being banned.
                </p>
                <p>
                  It should be duly noted that all accounts associated with a
                  chargeback will be automatically forfeited.{" "}
                </p>
                <br />
                <p>
                  <b>
                    Apex E-Commerce Services Authorized Representative
                    Signature:
                  </b>
                </p>
                <div className="pdf-signature">
                  {/* <input type="text" placeholder="signature" /> */}
                  {/* <button onClick={clear}>Clear</button>
                <div>
                  <SignaturePad ref={signaturePad}></SignaturePad>
                </div> */}
                </div>
              </div>

              <div className="pdf-disclaimer">
                <p>
                  Disclaimer of Warranties and Limitation of liability: Apex
                  E-Commerce Services LLC provides a licensed online software
                  service that facilitates the creation of accounts for
                  authorized users, which enables them access to a host of
                  services. The service provided by Apex E-Commerce Services LLC
                  is not to be confused or misconstrued to be anything else as
                  not to prejudice the operation and goodwill of the company.
                  Apex E-Commerce Services LLC bears no responsibility or
                  liability for any aspect of usage by users that are not in
                  conformity with our services as per the governing laws
                  concerning this industry. Lastly, if any provision of this
                  agreement is unenforceable or held illegal in a judicial
                  proceeding such provision shall be severed and held
                  inoperative, whilst holding the remaining portion of this
                  agreement operative and binding on both parties. Apex
                  E-Commerce Services LLC and Apex E-Commerce Services Ltd are
                  one in the same, registered in different countries.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className={"verification-continue-btn " + (isValid ? "complete" : "")}
          // disabled={!isValid}
          onClick={() => {
            console.log("Date of Birth Value:");
            console.log(dobvalue);
            setDOBValue(dobvalue);
            console.log("Date of Issue Value:");
            console.log(issuedatevalue);
            setIdIssueDateValue(issuedatevalue);
            console.log("Date of Expiration Value:");
            console.log(datevalue);
            setIdExpirationDateValue(datevalue);
            const trimmedName = (firstname + " " + lastname).trim();
            setFullnameValue(trimmedName);
            setIdNumber(idnumber);
            html2pdf()
              .set({
                margin: [0, 30, 0, 30],
              })
              .from(getFormHtml())
              .toPdf()
              .output("datauristring")
              .then(function (pdfAsString) {
                // // debugger;
                console.log(pdfAsString);
                setFormBase64(pdfAsString);
                setFormEmbedHtml(getFormHtml());
                setOpenReview(true);
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          Continue
        </button>
      </div>
    )
  );
};

export default VerificationAgreement;
