import React from "react";
import styled from "styled-components";
import bluecheckmark from "../images/icons/bluetick.svg";
import deleteIcon from "../images/icons/delete.svg";

const PasswordChangedSuccess = ({ closeModal }) => {
  const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: 500;
    border-radius: 20px;
  `;
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="close-cpm" onClick={() => closeModal(false)}>
              <img src={deleteIcon} alt="" />
            </div>

            <div className="delete-card">
              <img src={bluecheckmark} alt="" />
              <p>Password Changed</p>
            </div>
            <div className="delete-card-info">
              <p>Congratulations Zesan! Your password has been changed</p>
            </div>
            <button className="verification-continue-btn complete">
              Login
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default PasswordChangedSuccess;
