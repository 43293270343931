import actions from "../../images/icons/actions.svg";
import kyc from "../../images/icons/kycdone.svg";

const img = <img src={actions} alt="actions" />;
const done = <img src={kyc} alt="done" />;

export const data = [
  {
    no: "01",
    name: "Abdullah Khaled Bin",
    date: "23/05/22",
    customerID: done,
    selfie: done,
    customerAgreement: done,
    status: "Approved",
    action: img,
  },
  {
    no: "01",
    name: "Abdullah Khaled Bin",
    date: "23/05/22",
    customerID: done,
    selfie: done,
    customerAgreement: done,
    status: "Pending",
    action: img,
  },
  {
    no: "01",
    name: "Abdullah Khaled Bin",
    date: "23/05/22",
    customerID: done,
    selfie: done,
    customerAgreement: done,
    status: "Rejected",
    action: img,
  },
  {
    no: "01",
    name: "Abdullah Khaled Bin",
    date: "23/05/22",
    customerID: done,
    selfie: done,
    customerAgreement: done,
    status: "Approved",
    action: img,
  },
];

export const colNames = [
  "No.",
  "Name",
  "Date",
  "Customer ID",
  "Selfie",
  "Customer Aggreement",
  "Status",
  "Action",
];
