const ReportTemplate = () => {
  const styles = {
    page: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      lineHeight: "1.5",
      letterSpacing: "0.01px",
    },

    columnLayout: {
      display: "flex",
      justifyContent: "space-between",
      margin: "3rem 0 5rem 0",
      gap: "2rem",
    },

    column: {
      display: "flex",
      flexDirection: "column",
    },

    spacer2: {
      height: "2rem",
    },

    fullWidth: {
      width: "100%",
    },

    marginb0: {
      marginBottom: 0,
    },
  };
  return (
    <div className="text-base">
      <div style={styles.page}>
        <div style={styles.spacer2}></div>

        <div>
          <h2>(Date)</h2>
          <h2>(recipient name)</h2>
          <h2>(recipient address)</h2>
        </div>

        <br />
        <td>Re: Affiliate Contractor Confirmation Letter</td>

        <p>Dear (Client Name),</p>
        <p>
          We would like to thank you for engaging with our services as an
          affiliate contractor.
          <br />
          As a confirmation of this agreement, we request that you provide this
          letter of confirmation to any financial institutions involved in your
          payments. This will ensure that all payments are made directly from
          Apex E-Commerce Services, on behalf of the contracted parties, in
          accordance with applicable laws and regulations.
          <br />
          We look forward to continuing our business relationship with you and
          appreciate your trust in us. If you have any further questions or
          concerns regarding this agreement, please do not hesitate to contact
          us at your earliest convenience.
        </p>
        <br />
        <p>Regards,</p>
        <p>Apex E-Commerce Services Team</p>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ReportTemplate;
