import React, { useEffect, useRef, useState } from "react";
import upload from "../images/icons/upload.svg";
import toast, { Toaster } from "react-hot-toast";
import {
  getSpecificUserDocument,
  convertSignature,
  getKycDocumentTypes,
  compareImages,
  matchSignature,
  getDocumentUrl,
  getDocument,
  updateDocumentContent,
  updateDocumentStatus,
  updateDocumentNote,
} from "../api/document";
import SignaturePad from "react-signature-pad";
import { useCallback } from "react";
import { FadeLoader } from "react-spinners";
import { useParams } from "react-router-dom";
const VerificationSignature = ({
  continueBtn,
  saveScreenshot,
  maxSize,
  maxSizeInMB,
  documentToUpload,
  imageIdSignature,
  setImageIdSignature,
  frontBase64,
  setFrontBase64,
  sigImage,
  setSigImage,
  handleVerification,
  sigResult,
  failcount,
  processing,
  documentToResubmit,
  resubmitDocument,
}) => {
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true); // Initialize as empty
  const acceptedFileTypes = "image/png, image/jpeg";
  const [fileError, setFileError] = useState(false);
  const [isMarkOnSignaturePad, setIsMarkOnSignaturePad] = useState(false);

  const [tryAgain, setTryAgain] = useState(false);

  // Signature image
  const [sigImageBase64, setSigImageBase64] = useState(null);
  const sigImageRef = useRef();

  let signaturePad = useRef(null);
  let signature = "";

  const { id } = useParams();

  const [frontID, SetFrontID] = useState(null);

  // Function to check if the signature pad is empty
  const checkSignature = async () => {
    if (signaturePad.current) {
      const isEmpty = signaturePad.current.isEmpty();
      setIsSignatureEmpty(isEmpty);
      return isEmpty;
    }
    setIsSignatureEmpty(true); // If there's no signature pad or it's not initialized, consider it empty
    return true;
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e, isBack) => {
    const file = e.target.files[0];
    debugger;
    if (file && file.size > maxSize) {
      setFileError(true);
      toast.error(
        `File size exceeds the limit (${maxSizeInMB} MB). Please choose a smaller file.`
      );
    } else {
      setFileError(false);
      setSigImageBase64(await toBase64(file));
      clear();
      setIsMarkOnSignaturePad(false);
    }
  };

  const resetFileUploadWidget = async () => {
    setSigImageBase64(null);
    setIsMarkOnSignaturePad(true);
  };

  const markOnSignaturePadDetected = async () => {
    setIsSignatureEmpty(
      !signaturePad.current || signaturePad.current.isEmpty()
    );
    return true;
  };

  function clear() {
    setSigImage(null);
    signaturePad.current.clear();
    setIsSignatureEmpty(true);
  }

  const save = () => {
    if (!isSignatureEmpty) {
      signature = signaturePad.current.toDataURL("image/jpeg");
      setSigImage(signature);
      setImageIdSignature(signature);
    }
  };

  const performChecks = async (e) => {
    await save();
    setTimeout(() => {
      handleVerification(e);
    }, 2000);
  };

  useEffect(() => {
    save();
  }, [save, SignaturePad]);

  useEffect(() => {
    if (documentToResubmit != null) {
      const storedFrontOfID = localStorage.getItem("frontOfID");
      SetFrontID(JSON.parse(storedFrontOfID));
    }
  }, []);

  return (
    <div className="verification-form">
      <Toaster />

      <div className="verification-heading">
        <h1>Confirm Your Signature</h1>
        <p>
          To aid the verification process, please take a moment to draw your
          signature as close as possible to the signature on the same ID
          uploaded.
        </p>
      </div>
      <div className="relative flex flex-col items-center w-auto h-auto px-4 py-4 my-4 space-y-4 bg-white rounded-lg sm:w-full sm:mx-auto ">
        <div>
          {frontBase64 || documentToResubmit ? (
            <div className="preview-box">
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
                className="flex items-center justify-center mx-auto md:w-[500px] w-auto object-contain h-[300px]"
              >
                {" "}
                <img
                  src={frontBase64 ? frontBase64 : frontID}
                  alt="File Preview"
                  className="object-contain w-full h-full"
                />
              </div>{" "}
            </div>
          ) : null}
        </div>
      </div>
      <div className="verification-heading">
        <p>
          Please take your time and use the drawing tool provided to create your
          signature accurately.
        </p>
      </div>
      <div className="w-full mx-auto sig-input">
        {/* <button onClick={save}>SAVE</button> */}
        <button
          class="flex items-center px-3 py-1  ml-auto text-[#FF6838] outline bg-white outline-#FF6838 text-base font-bold rounded-md mb-2"
          onClick={clear}
        >
          Clear
        </button>
        <div
          className="sigpad"
          onMouseDown={() => setIsSignatureEmpty(false)}
          onTouchStart={() => setIsSignatureEmpty(false)}
        >
          <SignaturePad
            ref={signaturePad}
            onMouseDown={() => setIsSignatureEmpty(false)}
            onTouchStart={() => setIsSignatureEmpty(false)}
          ></SignaturePad>
        </div>

        {/* {sigImage && (
          <img
            src={sigImage}
            alt="my signiture"
            style={{
              display: "block",
              height: "100px",
            }}
            className="my-4"
          />
        )} */}
      </div>
      {failcount > 1 && (
        <div className="verification-heading">
          <p className="text-center">
            The automatic signature verification was unsuccessful again. Don't
            worry, you may proceed to the next step and our support agents will
            manually verify your documents, which may take a longer while.
          </p>
        </div>
      )}
      {failcount === 1 && (
        <div className="verification-heading-error">
          {!tryAgain ? (
            <p className="text-center">
              We regret to inform you that the automatic signature verification
              was unsuccessful. To proceed with the KYC process, we kindly ask
              you to redraw your signature accurately one more time.
            </p>
          ) : (
            ""
          )}
        </div>
      )}
      {failcount < 1 && (
        <button
          className="verification-continue-btn complete"
          disabled={isSignatureEmpty}
          onClick={async () => {
            if (documentToResubmit != null) {
              await save();
              localStorage.removeItem("frontOfID");
              continueBtn();
            } else {
              performChecks();
            }
          }}
        >
          Continue
        </button>
      )}
      {failcount === 1 && (
        <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
          {!tryAgain && (
            <button
              className="try-again-btn"
              disabled={isSignatureEmpty}
              onClick={() => {
                clear();
                setTryAgain(true);
              }}
            >
              Try again
            </button>
          )}

          {tryAgain && (
            <button
              className="verification-continue-btn complete"
              disabled={isSignatureEmpty}
              onClick={() => {
                performChecks();
              }}
            >
              Continue
            </button>
          )}
        </div>
      )}
      {failcount > 1 && (
        <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
          <button
            className="try-again-btn"
            disabled={isSignatureEmpty}
            onClick={() => {
              performChecks();
            }}
          >
            Try again
          </button>
          <button
            className="new-verification-continue-btn"
            disabled={isSignatureEmpty}
            onClick={(e) => {
              save();
              continueBtn(e);
            }}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default VerificationSignature;
