export const depositTableData = [
  {
    id: 1,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 2,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 3,
    depositmethod: "Credit card(***65)",
    status: "Decline",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "decline",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Proceeding",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "proceeding",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 4,
    depositmethod: "Credit card(***65)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
];
