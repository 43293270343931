import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import PasswordResponses from "react-pdf/dist/umd/PasswordResponses";

const PersonalDetails = ({
  selectedCountry,
  setSelectedCountry,
  continueBtn,
  data,
  setPersonalData,
  ocrData,
  setOcrData,
  selectedDocument,
}) => {
  const [idNumber, setIdNumber] = useState("");
  const [gender, setgender] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [dob, setDOB] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  // const borderColor = isCorrect ? "border-gray-200" : "border-orange-300";
  const borderColor = "border-gray-200";
  const [showToast, setShowToast] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [isTouched, setIsTouched] = useState(false);

  let isIssueDateEmpty = issueDate.trim() === "";
  let isExpDateEmpty = expiryDate.trim() === "";
  let isDobEmpty = dob.trim() === "";
  let isStateEmpty = state.trim() === "";
  let isCityEmpty = city.trim() === "";
  let isCountryEmpty = country.trim() === "";
  let isPostalCodeEmpty = postalCode.trim() === "";
  let isline1Empty = line1.trim() === "";
  let isGenderEmpty = gender.trim() === "";
  let isIdNumberEmpty = idNumber.trim() === "";
  let isFirstNameEmpty = first_name.trim() === "";
  let isLastNameEmpty = last_name.trim() === "";

  const canContinue =
    isIssueDateEmpty ||
    isExpDateEmpty ||
    isDobEmpty ||
    isStateEmpty ||
    isLastNameEmpty ||
    isCountryEmpty ||
    isPostalCodeEmpty ||
    isline1Empty ||
    isGenderEmpty ||
    isIdNumberEmpty ||
    isFirstNameEmpty;

  const setData = () => {
    setPersonalData({
      idNumber,
      gender,
      first_name,
      last_name,
      line1,
      line2,
      postalCode,
      country,
      city,
      state,
      dob,
      issueDate,
      expiryDate,
    });
  };

  const handleRadioChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);

    if (option === "show") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      toast((t) => (
        <div className="flex">
          <p className="leading-5 ">
            Edit your details to match your government-issued ID.
          </p>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="p-2 ml-4 text-sm border border-gray-400 rounded-md bg-gray-500/20"
          >
            Dismiss
          </button>
        </div>
      ));
    } else {
      toast.dismiss();
    }
  };

  // Data response from Identity Ocr Api
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let sex = ocrData?.sex || null;
    let nameValues = ocrData?.name?.split("\n") || null;
    let addressValues = ocrData?.add?.split("\n") || null;
    let countryValues = ocrData?.country?.split("\n") || null;
    let birth = ocrData?.dob || null;
    let City = ocrData?.city || null;
    let Country = ocrData?.country || null;
    let exp = ocrData?.exp || null;
    let idNumber = ocrData?.id || null;
    let idType = ocrData?.id_type || null;

    if (
      idType === "DRIVER'S LICENCE" ||
      selectedDocument === "Drivers License"
    ) {
      if (sex) {
        setgender(sex);
      }
      if (nameValues) {
        setFirstName(nameValues[1]);
        setLastName(nameValues[0]);
      }

      if (birth) {
        setDOB(birth);
      }
      if (City) {
        setCity(City);
      }

      if (Country) {
        setCountry(Country);
      }
      if (addressValues) {
        setLine1(addressValues[0]);
        setLine2(addressValues[1]);
        setState(addressValues[2]);
      }

      if (exp) {
        setExpiryDate(exp);
      }

      if (idNumber) {
        setIdNumber(idNumber);
      }
    } else if (idType === "ELECTOR" || selectedDocument === "National ID") {
      if (sex) {
        setgender(sex);
      }
      if (nameValues) {
        setFirstName(nameValues[0]);
      }

      if (birth) {
        setDOB(moment(birth, "MMM DD YYYY").format("YYYY-MM-DD"));
      }
      if (City) {
        setCity(City);
      }

      if (Country) {
        setCountry(Country);
      }
      if (addressValues) {
        setLine1(addressValues[0]);

        setState(addressValues[2]);
      }

      if (exp) {
        setExpiryDate(moment(exp, "MMM DD YYYY").format("YYYY-MM-DD"));
      }

      if (idNumber) {
        setIdNumber(idNumber);
      }
    } else {
      if (sex) {
        setgender(sex);
      }
      if (nameValues) {
        setFirstName(nameValues[0]);
      }

      if (birth) {
        setDOB(moment(birth, "DD MMM YYYY").format("YYYY-MM-DD"));
      }
      if (City) {
        setCity(City);
      }

      if (countryValues) {
        setCountry(countryValues[0]);
      }
      if (addressValues) {
        setLine1(addressValues[0]);
      }

      if (exp) {
        setExpiryDate(moment(exp, "DD MMM YYYY").format("YYYY-MM-DD"));
      }

      if (idNumber) {
        setIdNumber(idNumber);
      }
    }
  }, [ocrData]);

  return (
    <div className="my-4 space-y-4 personal-info">
      <Toaster
        toastOptions={{
          // Define default options
          className: "",
          duration: 999999999,
          style: {
            background: "#363636",
            color: "#fff",
          },
        }}
      />

      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">ID Issue date</p>
          <input
            type="date"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
            class={`shadow-sm border-2 rounded-lg h-full w-full p-4 ${
              isIssueDateEmpty ? "border-red-500" : borderColor
            }`}
            placeholder="Enter id issue date"
            required
          ></input>
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">ID expiry date</p>
          <input
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            type="date"
            class={`shadow-sm border-2 rounded-lg h-full w-full p-4 ${
              isExpDateEmpty ? "border-red-500" : borderColor
            }`}
            placeholder="Enter id expiration date"
          ></input>
        </div>
      </div>
      <div className="flex flex-col flex-1 gap-2">
        <p className="text-sm text-[#344054]">ID number</p>

        <input
          value={idNumber}
          onChange={(e) => setIdNumber(e.target.value)}
          type="text"
          class={`${
            isIdNumberEmpty ? "border-red-500" : borderColor
          } shadow-sm border-2  rounded-lg  p-4`}
          placeholder="Enter id number"
        ></input>
      </div>
      <br />
      <p className="text-xl">Personal details</p>
      <p className="text-sm font-light ">
        Please review and confirm that the following details are accurate.
      </p>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">First Name</p>
          <input
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            class={`${
              isFirstNameEmpty ? "border-red-500" : borderColor
            } shadow-sm border-2 w-auto rounded-lg p-4`}
            placeholder="Enter first name"
          ></input>
        </div>{" "}
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">Last Name</p>
          <input
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            class={`${
              isLastNameEmpty ? "border-red-500" : borderColor
            } shadow-sm border-2 w-auto rounded-lg p-4`}
            placeholder="Enter last name"
          ></input>
        </div>
      </div>
      <div class="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">Gender</p>
          <div class="relative flex items-center h-full after:border-black">
            {" "}
            <select
              id="gender"
              value={gender ? gender : null}
              onChange={(e) => setgender(e.target.value)}
              class={`bg-white px-3 py-2 h-[60px] transition-all cursor-pointer border-2 ${
                isGenderEmpty ? "border-red-500" : borderColor
              } rounded-lg flex-1 h-full text-black`}
            >
              <option disabled selected>
                Select a gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">Date of birth</p>
          <input
            value={dob}
            onChange={(e) => setDOB(e.target.value)}
            type="date"
            class={`shadow-sm border-2 rounded-lg h-full w-full p-4 ${
              isDobEmpty ? "border-red-500" : borderColor
            }`}
          ></input>
        </div>
      </div>
      <br />
      <p className="text-xl">Place of residence</p>
      <p className="text-sm font-light ">
        To verify your identity, we kindly ask for your address information.
        This should be the address where your bank statement, bills etc are
        mailed to.
      </p>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">Country</p>

          <input
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            type="text"
            class={`${
              isCountryEmpty ? "border-red-500" : borderColor
            } shadow-sm border-2  rounded-lg  p-4`}
          ></input>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">Address line 1</p>

          <input
            value={line1}
            onChange={(e) => setLine1(e.target.value)}
            type="text"
            class={`${
              isline1Empty ? "border-red-500" : borderColor
            } shadow-sm border-2  rounded-lg  p-4`}
            placeholder=""
          ></input>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">
            Address line 2 <span className="text-gray-400">(optional)</span>
          </p>

          <input
            value={line2}
            onChange={(e) => setLine2(e.target.value)}
            type="text"
            class={`${borderColor} shadow-sm border-2  rounded-lg  p-4`}
            placeholder=""
          ></input>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">City</p>

          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            class={`${borderColor} shadow-sm border-2  rounded-lg  p-4`}
            placeholder=""
          ></input>
        </div>
        <div className="flex flex-col flex-1 gap-2">
          <p className="text-sm text-[#344054]">
            State/parish/providence/region
          </p>

          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            class={`${
              isStateEmpty ? "border-red-500" : borderColor
            } shadow-sm border-2  rounded-lg  p-4`}
            placeholder=""
          ></input>
        </div>
      </div>
      <div className="flex flex-col flex-1 gap-2">
        <p className="text-sm text-[#344054]">Postal code</p>

        <input
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          type="text"
          class={`${
            isPostalCodeEmpty ? "border-red-500" : borderColor
          } shadow-sm border-2  rounded-lg  p-4`}
          placeholder="0000"
        ></input>
      </div>
      <div className="space-y-2">
        <p className="text-xl">
          The information above is accurate and corresponds to your government
          issued identification.
        </p>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="options"
              value="hide"
              checked={selectedOption === "hide"}
              onChange={(e) => {
                setIsCorrect(true);
                handleRadioChange(e);
              }}
              className="radio"
            />
            <p className="text-lg">Yes</p>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="options"
              value="show"
              checked={selectedOption === "show"}
              onChange={(e) => {
                setIsCorrect(false);
                handleRadioChange(e);
              }}
              className="radio"
            />
            <p className="text-lg">No</p>
          </div>
        </div>
      </div>
      <button
        // disable btn is any required field is empty
        disabled={selectedOption === null || !isCorrect || canContinue}
        className={`verification-continue-btn ${
          selectedOption != null && isCorrect ? "complete" : ""
        }`}
        onClick={() => {
          setData();
          continueBtn();
        }}
      >
        Continue
      </button>
    </div>
  );
};

export default PersonalDetails;
