import _ from "lodash";
import { useState } from "react";
import { getDocumentUrl, updateDocumentStatus } from "../../api/document";
import KycModal from "../KycModal";
import KycEditFormModal from "../KycEditFormModal";
import { toast } from "react-toastify";
const KycTable = ({ data = null, hover = true, refresh }) => {
  const columns = [
    { field: "upload.customer.Email", header: "Customer" },
    { field: "documentType.Type_Name", header: "Document Type" },
    { field: "status.Status_Name", header: "Status" },
  ];
  const [selectedDocument, setSelectedDocument] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const showDocumentModal = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>{columns && columns.map((head) => <th>{head.header}</th>)}</tr>
        </thead>
        <tbody>
          {data &&
            data.map((row) => (
              <tr className={`${hover && "hover"}`}>
                {columns.map((col) => {
                  // className={`${row.class === "decline" && "decline"}`}
                  return (
                    <td
                      className={`${row[col.class] && row[col.class]} `}
                      onClick={() => showDocumentModal(row)}
                    >
                      {_.get(row, col.field)}
                    </td>
                  );
                })}
                <td className="pdf-download">
                  <span>
                    <a href={getDocumentUrl(row)} target="_blank">
                      View
                    </a>
                  </span>
                  {_.get(row, "documentType.Type_Name") == "Form" && (
                    <span
                      onClick={() => {
                        setShowEditFormModal(true);
                        setSelectedDocument(row);
                      }}
                    >
                      <a href="#">Edit</a>
                    </span>
                  )}
                  <span
                    onClick={async () => {
                      await updateDocumentStatus(row.Document_ID, "VERIFIED");
                      toast("Successfully updated", { type: "success" });
                      refresh();
                    }}
                  >
                    <a href="#">Approve</a>
                  </span>
                  <span
                    onClick={async () => {
                      await updateDocumentStatus(row.Document_ID, "REJECTED");
                      toast("Successfully updated", { type: "success" });
                      refresh();
                    }}
                  >
                    <a href="#">Reject</a>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data != null ? null : <p>No data to show </p>}
      {showModal && (
        <KycModal closeModal={setShowModal} document={selectedDocument} />
      )}
      {showEditFormModal && (
        <KycEditFormModal
          closeModal={setShowEditFormModal}
          document={selectedDocument}
        />
      )}
    </div>
  );
};

export default KycTable;
