import React from "react";

// HISTORY TABLE CSS
import "./HistoryTable.css";

const RewardsHistoryTable = ({ data = null, hover = true }) => {
  const columns = [
    { field: "date", header: "Date" },
    { field: "info", header: "Info" },
    { field: "points", header: "Points", class: "class" },
  ];
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>{columns && columns.map((head) => <th>{head.header}</th>)}</tr>
        </thead>
        <tbody>
          {data &&
            data.map((row) => (
              <tr>
                {columns.map((col) => (
                  <td className={`${row[col.class] && row[col.class]}`}>
                    {row[col.field]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {data ? null : <p>No data to show </p>}
    </div>
  );
};

export default RewardsHistoryTable;
