export const IntroducedTableData = [
  {
    id: "#12345",
    country: "Rafsan Haque",
    joineddate: "01/05/22",
    lastdate: "23/05/22",
    volume: "01",
    commissionearned: "$20",
    status: "Paid",
    class: "success",
  },
  {
    id: "#12345",
    country: "Rafsan Haque",
    joineddate: "01/05/22",
    lastdate: "23/05/22",
    volume: "01",
    commissionearned: "$30",
    status: "Decline",
    class: "decline",
  },
  {
    id: "#12345",
    country: "Rafsan Haque",
    joineddate: "01/05/22",
    lastdate: "23/05/22",
    volume: "01",
    commissionearned: "$20",
    status: "Proceeding",
  },
];
