export const rewardsTableData = [
  {
    date: "23/05/22",
    info: "Daily Trade Rewards",
    points: "1",
    class: "success",
  },
  {
    date: "23/05/22",
    info: "30/30 day trade",
    points: "70",
    class: "success",
  },
  {
    date: "23/05/22",
    info: "Converted Points",
    points: "-500",
    class: "decline",
  },
  {
    date: "23/05/22",
    info: "Daily Trade Rewards",
    points: "1",
    class: "success",
  },
  {
    date: "23/05/22",
    info: "30/30 day trade",
    points: "70",
    class: "success",
  },
  {
    date: "23/05/22",
    info: "Converted Points",
    points: "-500",
    class: "decline",
  },
  {
    date: "23/05/22",
    info: "Daily Trade Rewards",
    points: "1",
    class: "success",
  },
  {
    date: "23/05/22",
    info: "30/30 day trade",
    points: "70",
    class: "success",
  },
  {
    date: "23/05/22",
    info: "Converted Points",
    points: "-500",
    class: "decline",
  },
];
