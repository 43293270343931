export const affiliateTableData = [
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
  {
    id: "#12345",
    name: "Rafsan Haque",
    commissionearned: "$20",
    joineddate: "01/05/22",
    startdate: "23/05/22",
    action: "Success",
    class: "success",
  },
];
