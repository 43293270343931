export const transferTableData = [
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Decline",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "decline",
  },
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Proceeding",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "proceeding",
  },
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
  {
    id: 1,
    transferto: "Trading AC(#47539)",
    transferfrom: "Walet AC(#zesan)",
    status: "Success",
    date: "23/05/22",
    amount: "$100000.00",
    invoice: "PDF",
    class: "success",
  },
];
