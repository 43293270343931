import React, { useState, useRef } from "react";
import { useEffect } from "react";
import down from "../images/down-arrow.svg";
import upload from "../images/icons/upload.svg";
import uploading from "../images/icons/uploading.svg";
import sampleId from "../images/sampleId.png";
import closedocbtn from "../images/icons/closedocbtn.svg";
import { listOfCountries } from "./CountryList";
import toast, { Toaster } from "react-hot-toast";
import { extractInformationOCR } from "../api/document";

import "../pages/Verification.css";
import { FadeLoader } from "react-spinners";
const VerificationForm = ({
  continueBtn,
  selectedCountry,
  setSelectedCountry,
  selectedDocument,
  setSelectedDocument,
  confirmDocumentSection,
  maxSize,
  maxSizeInMB,
  frontBase64,
  backBase64,
  setFrontBase64,
  setBackBase64,
  handleOCRRequest,
  frontFile,
  backFile,
  setFrontFile,
  setBackFile,
  processing,
  documentToResubmit,
  resubmitDocument,
  submit,
}) => {
  const [openCountry, setOpenCountry] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);

  const frontSideFileRef = useRef();
  const backSideFileRef = useRef();

  const [agreement, setAgreement] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const acceptedFileTypes = "image/png, image/jpeg, application/pdf";

  // const [selectedCountry, setSelectedCountry] = useState("Select your country");
  const countryOptions = listOfCountries;
  const [cselected, setcselected] = useState(false);
  // const countryOptions = useMemo(() => countryList().getData(), []);

  // const [selectedDocument, setSelectedDocument] = useState(
  //   "Select a document type"
  // );

  const [fileError, setFileError] = useState(false);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const documentOptions = ["National ID", "Drivers License", "Passport"];

  const handleFileChange = async (e, isBack) => {
    const file = e.target.files[0];

    debugger;
    if (file && file.size > maxSize) {
      setFileError(true);
      toast.error(
        `File size exceeds the limit (${maxSizeInMB} MB). Please choose a smaller file.`
      );
    } else {
      setFileError(false);
      if (!isBack) {
        setFrontFile(file);
        setFrontBase64(await toBase64(file));
      } else {
        setBackFile(file);
        setBackBase64(await toBase64(file));
      }
    }
  };

  const [filesValid, setFilesValid] = useState(false);
  useEffect(() => {
    const documentTypeSelected = selectedDocument != "Select a document type";
    const countrySelected = selectedCountry != "";

    switch (selectedDocument) {
      case "Passport":
        setFilesValid(frontBase64 != null);
        break;
      case "National ID":
      case "Drivers License":
      default:
        setFilesValid(frontBase64 != null && backBase64 != null);
        break;
    }
    if (
      filesValid &&
      selectedDocument !== "Select a document type" &&
      selectedCountry !== "" &&
      agreement
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    agreement,
    selectedDocument,
    selectedCountry,
    frontBase64,
    backBase64,
    isValid,
    filesValid,
  ]);

  return (
    <div className="verification-form">
      <Toaster />

      <div className="verification-heading">
        <h1>Upload a proof of your identity</h1>
        <p>
          Apex Markets Requires a valid government issue ID (drivers license,
          passport, national ID). Please ensure to upload a close-up image of
          the selected document type only. This image should be clear and
          legible, showing all details without any blurriness or obstruction.
          Kindly ensure that the document is well-lit and the information is
          easily readable before submission.
        </p>
      </div>

      <div className="select-container">
        <div className="dropdowns">
          <div class="custom-select">
            <p>Your Country</p>
            <div className="select">
              <span onClick={() => setOpenCountry(true)}>
                <input
                  type="text"
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  placeholder={
                    !selectedCountry != null && "Select your country"
                  }
                  value={selectedCountry}
                />
                <img src={down} alt="" />
              </span>
              {openCountry && (
                <ul>
                  {countryOptions
                    .filter(
                      (country) =>
                        selectedCountry == "" ||
                        country
                          .toLowerCase()
                          .includes(selectedCountry.toLowerCase())
                    )
                    .map((option) => (
                      <li
                        onClick={(e) => {
                          setSelectedCountry(e.target.textContent);
                          setOpenCountry(false);
                          setcselected(true);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
          <div class="custom-select">
            <p>Document type</p>
            <div className="select">
              <span onClick={() => setOpenDocument(!openDocument)}>
                <p>{selectedDocument}</p>
                <img src={down} alt="" />
              </span>
              {openDocument && (
                <ul>
                  {documentOptions.map((option) => (
                    <li
                      onClick={(e) => {
                        setSelectedDocument(e.target.textContent);
                        setOpenDocument(false);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {fileError && (
          <p className="px-2 pb-2 text-center text-red-500">
            File size exceeds the limit {maxSizeInMB} MB. Please choose a
            smaller file.
          </p>
        )}
        {selectedDocument === "Passport" ? (
          <div className="passport-upload">
            <div
              className="relative document-upload-box"
              onClick={() => frontSideFileRef.current.click()}
              // style={{
              //   ...(frontBase64 != null &&
              //     frontBase64.startsWith("data:image") && {
              //       backgroundImage: `url("${frontBase64}")`,
              //       backgroundRepeat: "no-repeat",
              //       backgroundSize: "contain",
              //       backgroundPosition: "center",
              //     }),
              // }}
            >
              <img src={upload} alt="" />
              <div className="document-upload-box-title">
                Upload Passport Photo
              </div>
              <div className="document-upload-box-text">
                upload a clear and close up image of your passport <br />
                Supports:JPG, PNG, PDF
              </div>

              {frontBase64 != null ? (
                <div className="document-upload-box-btn">Change File</div>
              ) : (
                <div className="document-upload-box-btn">Choose a File</div>
              )}
              {frontBase64 != null && frontBase64.startsWith("data:image") && (
                <div className="flex justify-center pt-5">
                  <img
                    src={frontBase64}
                    alt=""
                    className="h-[200px] w-[300px] object-contain"
                  />
                </div>
              )}
            </div>
            <input
              type={"file"}
              hidden
              ref={frontSideFileRef}
              multiple={false}
              accept={acceptedFileTypes}
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div className="document-upload">
            <div>
              <div
                className="document-upload-box"
                onClick={() => frontSideFileRef.current.click()}
                // style={{
                //   ...(frontBase64 != null &&
                //     frontBase64.startsWith("data:image") && {
                //       backgroundImage: `url("${frontBase64}")`,
                //       backgroundRepeat: "no-repeat",
                //       backgroundSize: "contain",
                //     }),
                // }}
              >
                <input
                  type={"file"}
                  hidden
                  ref={frontSideFileRef}
                  multiple={false}
                  accept={acceptedFileTypes}
                  onChange={(e) => {
                    handleFileChange(e, false);
                  }}
                />
                <img src={upload} alt="" />
                <div className="document-upload-box-title">
                  Front side of your document
                </div>
                <div className="document-upload-box-text">
                  Upload a clear picture of the front side of your document{" "}
                  <br />
                  Supports:JPG, PNG, PDF
                </div>
                {frontBase64 != null ? (
                  <div className="document-upload-box-btn">Change File</div>
                ) : (
                  <div className="document-upload-box-btn">Choose a File</div>
                )}
                {frontBase64 != null &&
                  frontBase64.startsWith("data:image") && (
                    <div className="flex justify-center pt-5">
                      <img
                        src={frontBase64}
                        alt=""
                        className="h-[200px] w-[300px] object-contain"
                      />
                    </div>
                  )}
              </div>
            </div>

            <div>
              <div
                className="document-upload-box"
                onClick={() => backSideFileRef.current.click()}
                // style={{
                //   ...(backBase64 != null &&
                //     backBase64.startsWith("data:image") && {
                //       backgroundImage: `url("${backBase64}")`,
                //       backgroundRepeat: "no-repeat",
                //       backgroundSize: "contain",
                //     }),
                // }}
              >
                <img src={upload} alt="" />
                <div className="document-upload-box-title">
                  Back side of your document
                </div>
                <div className="document-upload-box-text">
                  Upload a clear picture of the back side of your document{" "}
                  <br />
                  Supports:JPG, PNG, PDF
                </div>
                {backBase64 != null ? (
                  <div className="document-upload-box-btn">Change File</div>
                ) : (
                  <div className="document-upload-box-btn">Choose a File</div>
                )}
                {backBase64 != null && backBase64.startsWith("data:image") && (
                  <div className="flex justify-center pt-5">
                    <img
                      src={backBase64}
                      alt=""
                      className="h-[200px] w-[300px] object-contain"
                    />
                  </div>
                )}

                {/* <div className="document-upload-box-btn active">Choose a File</div> */}
                {/*-------------- No file uploaded -----------------*/}
                {/*-------- Uploading a file ------------- */}
                {/* <img src={uploading} alt="" />
                <div className="document-upload-box-text">
                  Uploading <span className="blue">zesanidphoto.png</span>
                </div>
                <div className="document-upload-box-btn dark">Choose a File</div> */}
                {/* ----------Uploading a file------------------------  */}
                {/*------------ FILE UPLOADED ------------- */}
                {/* <img src={uploading} alt="" />
                <div className="document-upload-box-text">
                  Uploading <span className="blue">zesanidphoto.png</span>
                </div>
                <div className="document-upload-box-btn dark">Choose a File</div> */}
                {/* --------------FILE UPLOADED------------------------  */}
              </div>
            </div>

            <input
              type={"file"}
              hidden
              ref={backSideFileRef}
              multiple={false}
              accept={acceptedFileTypes}
              onChange={(e) => {
                handleFileChange(e, true);
              }}
            />
          </div>
        )}
      </div>
      <div className="confirmation-checkbox df-save-card">
        <input
          type="checkbox"
          checked={agreement}
          onChange={(event) => setAgreement(event.target.checked)}
        />
        <span className="label"></span>
        {selectedDocument === "Passport" ? (
          <p>
            I Confirm that I uploaded valid government-issued Passport. This
            Passport includes my picture, signature, name, date of birth, and
            address.
          </p>
        ) : (
          <p>
            I Confirm that I uploaded valid government-issued photo ID. This ID
            includes my picture, signature, name, date of birth, and address.
          </p>
        )}
      </div>
      <button
        className={"verification-continue-btn" + (isValid ? " complete" : "")}
        onClick={documentToResubmit != null ? submit : handleOCRRequest}
        disabled={!isValid}
      >
        Continue
      </button>
    </div>
  );
};

export default VerificationForm;
