import React from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input

// icons

import deleteIcon from "../images/icons/delete.svg";

// css
import "./DepositForm.css";

const EditCardInfo = ({ closeModal }) => {
  const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: 500;
    border-radius: 20px;
  `;
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Edit Credit/Debit Card</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardHolde Name">Card Holder Name</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                id="CardHolderName"
                name="CardHolderName"
                placeholder="Zesan Howlader"
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Card Number</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                id="CardNumber"
                name="CardNumber"
                placeholder=""
              />
            </div>
            <div className="form-flex-row">
              <div>
                <div className="form-label-warning">
                  <label htmlFor="ExpiryDate">Expiry Date</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    id="ExpiryDate"
                    name="ExpiryDate"
                    placeholder="MM/YY"
                  />
                </div>
              </div>
              <div>
                <div className="form-label-warning">
                  <label htmlFor="SecurityCode">Security Code (CVV)</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    id="SecurityCode"
                    name="SecurityCode"
                    placeholder="Enter CVV"
                  />
                </div>
              </div>
            </div>

            <button className="verification-continue-btn complete">
              Update
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditCardInfo;
