import React, { useRef, useState } from "react";
import upload from "../images/icons/upload.svg";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { FadeLoader } from "react-spinners";

const VerificationSelfie = ({
  continueBtn,
  saveScreenshot,
  maxSize,
  maxSizeInMB,
  faceVerification,
  faceFailcount,
  screenshot,
  processing,
  documentToResubmit,
  resubmitDocument,
}) => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [openModal, setOpenModal] = useState(false);
  const [webcam, setWebcam] = useState();
  const [screenshotBase64, setScreenshotBase64] = useState();

  const [tryAgain, setTryAgain] = useState(false);

  const submitScreenshot = () => {
    faceVerification();
  };

  const acceptedFileTypes = "image/png, image/jpeg";
  const screenshotRef = useRef();
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const documentOptions = ["National ID", "Drivers License", "Passport"];

  const [fileError, setFileError] = useState(false);

  const handleFileChange = async (e, isBack) => {
    const file = e.target.files[0];
    debugger;
    if (file && file.size > maxSize) {
      setFileError(true);
      toast.error(
        `File size exceeds the limit (${maxSizeInMB} MB). Please choose a smaller file.`
      );
    } else {
      setFileError(false);
      saveScreenshot(await toBase64(file));
      setScreenshotBase64(await toBase64(file));
    }
  };

  useEffect(() => {
    saveScreenshot(screenshot);
    console.log(screenshot);
  }, [screenshot, saveScreenshot]);
  return (
    <div className="verification-form">
      <Toaster />

      <div className="verification-heading">
        <h1>Upload a Selfie holding id beside face</h1>
        <p>
          As part of our document verification process, we kindly request that
          you upload a selfie holding your ID next to your face. This image
          should clearly show both your face and the ID document, ensuring all
          details are visible and legible. Please ensure the ID is clearly
          visible and not obstructed in any way. Ensure that your face is
          well-lit and easily recognizable in the photo.
        </p>
      </div>
      <input
        type={"file"}
        hidden
        ref={screenshotRef}
        multiple={false}
        accept={acceptedFileTypes}
        onChange={handleFileChange}
      />
      {fileError && (
        <p className="px-2 pb-2 text-center text-red-500">
          File size exceeds the limit {maxSizeInMB} MB. Please choose a smaller
          file.
        </p>
      )}
      <div
        className="document-upload-box"
        onClick={() => screenshotRef.current.click()}
        // style={{
        //   ...(screenshotBase64 != null &&
        //     screenshotBase64.startsWith("data:image") && {
        //       backgroundImage: `url("${screenshotBase64}")`,
        //       backgroundRepeat: "no-repeat",
        //       backgroundSize: "contain",
        //     }),
        // }}
      >
        <img src={upload} alt="" />
        <div className="document-upload-box-title">Selfie with ID</div>
        <div className="document-upload-box-text">
          Upload your selfie with ID <br />
          Supports:JPG, PNG
        </div>
        {screenshotBase64 != null ? (
          <div className="document-upload-box-btn">Change File</div>
        ) : (
          <div className="document-upload-box-btn">Choose a File</div>
        )}
        {screenshotBase64 != null &&
          screenshotBase64.startsWith("data:image") && (
            <div className="flex justify-center pt-5">
              <img
                src={screenshotBase64}
                alt=""
                className="h-[200px] w-[300px] object-contain"
              />
            </div>
          )}
      </div>

      {faceFailcount > 1 && (
        <div className="verification-heading">
          <p className="text-center">
            The automatic face match verification was unsuccessful again. Don't
            worry, you may proceed to the next step and our support agents will
            manually verify your documents, which may take a longer while.
          </p>
        </div>
      )}
      {faceFailcount === 1 && (
        <div className="verification-heading-error">
          {!tryAgain ? (
            <p className="text-center ">
              We regret to inform you that the automatic face match verification
              was unsuccessful. To proceed with the KYC process, we kindly ask
              you to try uploading a another photo and try again one more time
            </p>
          ) : (
            ""
          )}
        </div>
      )}
      {faceFailcount < 1 && (
        <button
          className="verification-continue-btn complete"
          disabled={screenshot == null}
          onClick={documentToResubmit != null ? continueBtn : submitScreenshot}
        >
          Continue
        </button>
      )}
      {faceFailcount === 1 && (
        <>
          {!tryAgain && (
            <button
              className="try-again-btn"
              disabled={screenshot == null}
              onClick={() => {
                setScreenshotBase64(null);
                setTryAgain(true);
              }}
            >
              Try again
            </button>
          )}
          {tryAgain && (
            <button
              className="verification-continue-btn complete"
              disabled={screenshot == null}
              onClick={submitScreenshot}
            >
              Continue
            </button>
          )}
        </>
      )}
      {faceFailcount > 1 && (
        <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
          <button
            className="try-again-btn"
            disabled={screenshot == null}
            onClick={submitScreenshot}
          >
            Try again
          </button>
          <button
            className="new-verification-continue-btn"
            disabled={screenshot == null}
            onClick={() => {
              saveScreenshot(screenshot);
              continueBtn();
            }}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default VerificationSelfie;
