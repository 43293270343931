import React from "react";
import AppBar from "../components/AppBar";
import AccountSettingsLinks from "../components/AccountSettingsLinks";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import {
  getUserDocuments,
  getSpecificUserDocumentStream,
} from "../api/document";
import { kycVerification } from "../api/customer";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./KycVerificationSettings.css";
import OverlayLoader from "../components/OverlayLoader";
import { AiOutlineClose } from "react-icons/ai";

const KycVerificationSettings = ({ showNav }) => {
  showNav(true);
  const navigate = useNavigate();
  const customer = useSelector(selectCurrentCustomer);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [showStatus, setShowStatus] = useState(false);
  const [warning, setWarning] = useState(false);
  const [statusMessage, setStatusMessage] = useState(
    "Your documents have been successfully submitted for verification! \
  This process can take anywhere from a few minutes to 48 hours. You will receive an email when the process is completed."
  );

  const [frontOfID, setFrontOfID] = useState();

  const loadDocuments = async () => {
    const documents = await getUserDocuments();
    if (documents != null) {
      setDocuments(documents);
      //const documentStatuses = getDocumentStatuses();
      if (
        customer?.Status?.toUpperCase() === "PENDING" &&
        customer?.Ran_Automated_KYC !== 1 &&
        documents?.length >= 4
      ) {
        //await automatedKycVerification(documents, false);
      }
    }
    //await loadStatusMessage(documents);
  };

  const getDocumentStatuses = () => {
    let documentStatuses = documents.map((document) => {
      if (document?.documentType?.Type_Name === "Signed Signature") {
        return undefined;
      }
      return document?.status?.Status_Name;
    });

    documentStatuses = documentStatuses.filter((n) => n);
    return documentStatuses;
  };

  const setFrontOfId = async (docs = documents) => {
    let frontOfId = null;
    try {
      const idDocumentTypes = ["Drivers License", "National ID", "Passport"];
      const foundDocument = docs.find((document) =>
        idDocumentTypes.includes(document?.documentType?.Type_Name)
      );

      if (foundDocument) {
        frontOfId = await getSpecificUserDocumentStream(
          foundDocument?.Document_ID
        );
        if (frontOfId != null) {
          localStorage.setItem("frontOfID", JSON.stringify(frontOfId));
        } else {
          return false;
        }
      } else {
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error", error);
      return false;
    }
  };

  const navigateBtn = async (document) => {
    const result = await setFrontOfId();
    if (result) {
      navigate(`/resubmit/${document.Document_ID}`);
    }
  };

  /*const loadStatusMessage = async (documents) => {
    const documentStatuses = getDocumentStatuses();

    switch (customer.Status?.toUpperCase()) {
      case "VERIFIED":
        if (documents?.length >= 4) {
          setShowStatus(false);
          setWarning(false);
          setStatusMessage("");
        }
        break;
      case "PENDING":
        if (documents?.length >= 4) {
          setShowStatus(true);
          setWarning(true);
          if (documentStatuses.includes("REJECTED")) {
            setStatusMessage(
              "Not all your documents were verified. Please check the reason which should be under column 'Note', then scroll to the right-most column 'Action' to resubmit only that document. Repeat until all documents previously rejected are in a pending state, and then click the 'Verify' button below the table"
            );
          } else {
            setStatusMessage(
              "Not all your documents were verified. Please click the 'Verify' button below the table to retry"
            );
          }
        } else if (documents?.length < 4 && documents?.length > 0) {
          setShowStatus(true);
          setWarning(true);
          setStatusMessage(
            "Some required documents are missing! This may be due to uploading the same file twice. Please go back to the dashboard and redo the KYC process."
          );
        }
        break;
      default:
        if (documents?.length >= 4) {
          setShowStatus(true);
          setWarning(true);
          setStatusMessage(
            "Not all your documents were verified. Please check the reason which should be under column 'Note', then scroll to the right-most column 'Action' to resubmit only that document. Repeat until all documents previously rejected are in a pending state, and then click the 'Verify' button below the table"
          );
        } else if (documents?.length < 4) {
          setShowStatus(true);
          setWarning(true);
          setStatusMessage(
            "Some required documents are missing! Please contact our support team"
          );
        }
    }
  };*/

  const automatedKycVerification = async (docs = documents, reload = true) => {
    let errorState = false;
    //setLoading(true);
    const documentStatuses = getDocumentStatuses();

    if (documentStatuses.includes("REJECTED")) {
      /*toast("Some documents have been rejected. Resubmit and try again!", {
        type: "error",
      });
      setLoading(false);*/
      return;
    }

    const idDocumentTypes = ["Drivers License", "National ID", "Passport"];

    console.log(docs);

    try {
      const frontOfId = await getSpecificUserDocumentStream(
        docs.find((document) =>
          idDocumentTypes.includes(document?.documentType?.Type_Name)
        )?.Document_ID
      );

      const documentToUpload = {
        files: [frontOfId],
      };

      const screenshot = await getSpecificUserDocumentStream(
        docs.find((document) => document?.documentType?.Type_Name === "Selfie")
          ?.Document_ID
      );

      const imageIdSignature = await getSpecificUserDocumentStream(
        docs.find(
          (document) => document?.documentType?.Type_Name === "Signed Signature"
        )?.Document_ID
      );

      const form = docs.find(
        (document) => document?.documentType?.Type_Name === "Form"
      );

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(
        form?.Registration_Html,
        "text/html"
      );
      const govIdText =
        htmlDoc?.getElementsByClassName("reg-info-row col")?.[0]?.children?.[1]
          ?.innerText;
      const nameText =
        htmlDoc?.getElementsByClassName("reg-info-row")?.[0]?.children?.[1]
          ?.innerText;
      const dobText =
        htmlDoc?.getElementsByClassName("reg-info-row")?.[4]?.children?.[1]
          ?.innerText;
      const idExpirationDateText = govIdText
        .match(/Expiry Date:\s.*\s[0-9]+/)?.[0]
        .replace("Expiry Date:", "")
        .trim();

      const fullnameValue = nameText;
      const idNumber = govIdText
        .match(/Number:\s.*\sE/)?.[0]
        ?.replace("Number: ", "")
        .replace(" E", "");
      const dobValue = dobText;
      const idExpirationDateValue = idExpirationDateText;

      const obj = {
        documentToUpload,
        imageIdSignature,
        screenshot,
        dobValue,
        idExpirationDateValue,
        fullnameValue,
        idNumber,
      };

      console.log(obj);

      const res = await kycVerification(obj);
    } catch (err) {
      console.error(err);
      errorState = true;
    } finally {
      //setLoading(false);
      if (reload && !errorState) {
        setTimeout(() => {
          window.top.location.reload();
        }, 1000);
      }
    }
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    customer != null && (
      <>
        <OverlayLoader loading={loading}></OverlayLoader>
        <div className="dashboard-page">
          <AppBar title="Account Settings"></AppBar>
          <div className="dashboard-container">
            <AccountSettingsLinks></AccountSettingsLinks>

            <div className="security-line"></div>
            <div className="relative profile-picture-settings">
              <div className="profile-picture-text">
                <span>Current KYC Status</span>
                <div className="edit-profile-pic">
                  {/* not verified button */}
                  {/* <button className="not-verified">Not Verified</button> */}
                  <button>{customer?.Status}</button>
                </div>
              </div>
            </div>
            <div className="security-line"></div>

            <div>
              {customer?.Status?.toUpperCase() === "PENDING" &&
              documents?.length >= 4 ? (
                <>
                  {
                    <div class="bg-green-500 text-white font-bold rounded-md px-4 py-4 lg:min-h-[100px] h-auto w-full flex flex-col justify-center">
                      <div className="flex justify-between gap-10">
                        <p className="text-xs font-light leading-4 md:text-sm">
                          {statusMessage}
                        </p>
                        {/*<AiOutlineClose
                          className="flex-shrink-0 ml-auto text-xl cursor-pointer"
                          onClick={() => setShowStatus(false)}
                        ></AiOutlineClose>*/}
                      </div>
                    </div>
                  }
                </>
              ) : null}
            </div>

            <div className="submitted-documents">
              <h2>Submited Documents</h2>
              <div className="table-container kyc-table">
                <table>
                  <thead>
                    <tr>
                      <th>Document Type</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Note</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document) => {
                      if (document?.documentType?.Type_Name === "Form") {
                        return undefined;
                      }
                      return (
                        <>
                          <tr>
                            <td>{document?.documentType?.Type_Name}</td>
                            <td>
                              {document?.Date_Created
                                ? moment(document?.Date_Created).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </td>
                            <td>
                              {document?.status?.Status_Name?.toUpperCase() ===
                              "PENDING"
                                ? "PENDING"
                                : document?.status?.Status_Name.toUpperCase()}
                            </td>
                            <td>
                              {customer?.Status?.toUpperCase() === "PENDING"
                                ? ""
                                : document?.Note}
                            </td>
                            <td>
                              {document?.status?.Status_Name?.toUpperCase() ===
                                "REJECTED" && (
                                <>
                                  {document?.documentType?.Type_Name ===
                                  "Signed Signature" ? (
                                    <button
                                      onClick={() => {
                                        navigateBtn(document);
                                      }}
                                      style={{ textDecoration: "underline" }}
                                    >
                                      Resubmit
                                    </button>
                                  ) : (
                                    <a
                                      href=""
                                      onClick={() => {
                                        // debugger;
                                        navigate(
                                          `/resubmit/${document.Document_ID}`
                                        );
                                      }}
                                      style={{ textDecoration: "underline" }}
                                    >
                                      Resubmit
                                    </a>
                                  )}
                                </>
                              )}
                              {document?.status?.Status_Name?.toUpperCase() ===
                                "MISSING" && (
                                <>
                                  <a
                                    onClick={() => {
                                      navigate(
                                        `/resubmit/${document?.Upload_Type}`
                                      );
                                    }}
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Upload
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default KycVerificationSettings;
