import React from "react";
import "./VerificationProgressBar.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

// React Step Progress Bar Library
// https://github.com/pierreericgarcia/react-step-progress-bar
const VerificationProgressBar = (props, { setStep }) => {
  return (
    <ProgressBar
      className="VerificationProgressBar"
      percent={((props.step - 1) * 100) / 4}
      filledBackground="#008ffd"
      height={4}
    >
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div className={`step ${accomplished ? "completed" : ""}`}>1</div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div className={`step ${accomplished ? "completed" : ""}`}>2</div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div className={`step ${accomplished ? "completed" : ""}`}>3</div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div className={`step ${accomplished ? "completed" : ""}`}>4</div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div className={`step ${accomplished ? "completed" : ""}`}>5</div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default VerificationProgressBar;
