import profile from "../images/profile-icon.png";

export const TraderData = [
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
  {
    name: "Zesan H.",
    gain: "150%",
    img: profile,
  },
];
