import React from "react";
import { useNavigate } from "react-router-dom";

const VerificationComplete = () => {
  const navigate = useNavigate();
  return (
    <div className="verification-form complete">
      <div className="verification-heading">
        <h1>We're verifying your Documents</h1>
        <p>We will send an email you once your verification has completed.</p>
      </div>
      <button
        className="verification-continue-btn complete"
        onClick={() => navigate("/dashboard")}
      >
        Back to Dashboard
      </button>
    </div>
  );
};

export default VerificationComplete;
