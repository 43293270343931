import React from "react";

const FullscreenModal = ({ onClose, children }) => {
  return (
    <div className="fullscreen-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

export default FullscreenModal;
