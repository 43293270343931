export const PayoutData = [
  {
    id: "#12345",
    trade: "$2323.00",
    closeddate: "01/05/22 - 03/01/22",
    loginid: "#12345",
    symbol: "23",
    volume: "01",
    commissionearned: "$20",
    status: "Paid",
    class: "success",
  },
  {
    id: "#12345",
    trade: "$2323.00",
    closeddate: "01/05/22 - 03/01/22",
    loginid: "#12345",
    symbol: "23",
    volume: "01",
    commissionearned: "$20",
    status: "Decline",
    class: "decline",
  },
  {
    id: "#12345",
    trade: "$2323.00",
    closeddate: "01/05/22 - 03/01/22",
    loginid: "#12345",
    symbol: "23",
    volume: "01",
    commissionearned: "$20",
    status: "Proceeding",
  },
];
